<template>
  <div class="container">
    <ScrollNotice />
    <div class="content">
      <div class="body">
        <div class="body_left">
          <div class="head">
            <p class="contact">CONTACT</p>
            <p class="title">联系我们</p>
            <p class="intro">{{info.intro}}</p>
          </div>
          <el-button> <i class="iconfont icon-QQ" style="margin-right: 18px"></i>在线客服</el-button>
          <div class="info">
            <p>合作电话: {{info.colPhone}}</p>
            <p>联系QQ: {{info.qq}}</p>
            <p>联系邮箱: {{info.email}}</p>
            <p>办公地址: {{info.address}}</p>
          </div>
        </div>
        <div class="body_right">
          <div class="title">咨询热线</div>
          <div class="item">
            <p class="title">服务热线</p>
            <p class="tel">{{info.serveTel}}</p>
          </div>
          <el-divider />
          <div class="item">
            <p class="title">合作热线</p>
            <p class="tel">{{info.colTel}}</p>
          </div>
          <p class="time">服务时间：{{info.time}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ScrollNotice from '@/components/ScrollNotice'
  export default {
    name: "ContactIndex",
    components: { ScrollNotice },
    data() {
      return {
        info: {
          colPhone: '18210791139',
          serveTel: '18210791139',
          colTel: '18210791139',
          qq: '',
          email: 'wmylzx@126.com',
          time:'周一至周五',
          address: '北京市大兴区兴华大街19号院23号楼',
          intro: ' 祝你我携手共荣，共铸辉煌！您的健康，我们的标准！您的满意，我们的追求！',
          icon: require('@/assets/images/qq.png')
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.container{
  width: 100%;
  height: 100%;
  .content{
    padding: 132px 0 183px;
    position: relative;
    background: url('../../assets/images/concatbg.jpg') no-repeat center top;
    .body{
      width: 1200px;
      margin: 0 auto;
      height: 633px;
      display: flex;
      flex-direction: row;
      .body_left{
        width: 837px;
        height: 100%;
        background-color: #fff;
        padding: 70px 84px;
        box-sizing: border-box;
        .head{
          font-size: 32px;
          height: 138px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .contact{
            font-size: 36px;
            color: #e5e5e5;
            font-family: Arial;
            letter-spacing: 3px;
          }
          .title{
            letter-spacing: 2px;
            font-weight: bold;
            color: #262626;
          }
          .intro{
            color: #7f7f7f;
            font-size: 15px;
            margin-top: 2px;
          }
        }
        .info{
          color: #7f7f7f;
          height: 126px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .body_right{
        width: 363px;
        height: 100%;
        padding: 85px;
        box-sizing: border-box;
        color: #fff;
        background-color: $green;
        .title{
          font-size: 18px;
          margin-bottom: 66px;
        }
        .item{
          .title{
            font-size: 10px;
            margin-bottom: 13px;
          }
          .tel{
            font-size: 17px;
          }
        }
        .el-divider{
          width: 56px;
          margin: 50px 0;
        }
        .time{
          margin-top: 27px;
        }
      }
    }
  }
}
.body_left /deep/ .el-button--default{
  margin: 45px 0 38px;
  padding: 0 50px;
  width: 220px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px!important;
  font-size: 16px;
}
</style>
